<template>
  <div class="filter-roles">
    <div class="filter-roles__btn-block">
      <div class="filter-roles__btn-left">
        <div class="filter-roles__btn-left__label">Фильтр</div>
        <div class="filter-roles__btn-left__reset-btn" @click="resetFilter">Сбросить</div>
      </div>
      <div @click="closeLeftSidebar" class="lg:tw-hidden">
        <zem-icon class="cursor-pointer" name="close" size="18" />
      </div>
    </div>
    <div class="filter-roles__dropdown mt-2 max-lg:tw-w-1/2 max-md:tw-w-full">
      <!--      <div class="zem-dropdown mb-0">-->
      <!--        <label class="filter-roles__label">Номер заказа</label>-->
      <!--        <ZemInput v-model="orderNumber" class="mt-0"></ZemInput>-->
      <!--      </div>-->

      <div class="filter-roles__label mt-3">Дата добавления</div>
      <div class="filter-roles__checkbox-block">
        <ZemCheckbox :value="filter.checkbox[0]" @input="inputCheckbox(0, $event)">Сегодня</ZemCheckbox>
        <ZemCheckbox :value="filter.checkbox[1]" @input="inputCheckbox(1, $event)">Вчера</ZemCheckbox>
        <ZemCheckbox :value="filter.checkbox[2]" @input="inputCheckbox(2, $event)">Последние 7 дней</ZemCheckbox>
        <ZemCheckbox :value="filter.checkbox[3]" @input="inputCheckbox(3, $event)">Последний месяц</ZemCheckbox>
      </div>
      <div class="filter-roles__period-block mt-3">
        <label class="filter-roles__label">Период создания</label>
        <date-picker
          v-model="date"
          format="DD.MM.YYYY"
          locale="ru"
          placeholder="Выбрать период"
          range
          value-type="format"
          @change="changeRangeDate('date', $event, 'created_at')"
        >
          <template v-slot:footer>
            <div style="text-align: left" class="tw-grid tw-grid-cols-4 tw-gap-3 tw-w-full">
              <button
                v-for="shortcut in shortcuts"
                :key="shortcut"
                class="tw-text-xs tw-font-normal tw-text-[#000000ca] tw-p-1.5 tw-rounded tw-bg-[#F5F5F5] hover:tw-bg-[#0DB2B2] hover:tw-text-white tw-transition-all"
                @click="onShortcutClick('date', shortcut, 'created_at')"
              >
                {{ shortcut }}
              </button>
            </div>
          </template>
        </date-picker>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="filter-roles__label">Создал</label>
        <ZemDropdownList
          v-model="selectedCreatedBy"
          :components="{OpenIndicator}"
          :options="storeUsers ? storeUsers : []"
          class="my-0"
          placeholder="Выбрать"
          @input="changeCreatedBy($event)"
        >
          <template #open-indicator="{attributes}">
            <img :src="require('@/assets/icons/dropdown-arrow.svg')" alt="" />
          </template>
        </ZemDropdownList>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="filter-roles__label">Статус</label>
        <ZemDropdownList
          v-model="selectedStatus"
          :components="{OpenIndicator}"
          :options="statuses"
          class="my-0"
          label="title"
          multiple
          placeholder="Выбрать"
        >
          <template #open-indicator="{attributes}">
            <img :src="require('@/assets/icons/dropdown-arrow.svg')" alt="" />
          </template>
        </ZemDropdownList>
      </div>
    </div>
  </div>
</template>

<script>
import ZemCheckbox from './ui/Checkbox'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import ZemDropdownList from './ui/ZemDropdownList'
import 'vue-select/dist/vue-select.css'
import ZemIcon from '@/components/ui/ZemIcon'
import ZemInput from '@/components/ui/ZemInput.vue'
import EstimatesService from '@/services/estimates.service'
import moment from 'moment/moment'
import UsersService from '@/services/users.service'
import shortcuts from '@/assets/scripts/shortcuts'
import {selectDay} from '@/assets/scripts/scripts'

export default {
  name: 'FilterEstimatesForm',

  components: {
    ZemInput,
    ZemCheckbox,
    DatePicker,
    ZemDropdownList,
    ZemIcon,
  },

  data() {
    return {
      orderNumber: '',
      statuses: [
        {title: 'Выполнено', value: 'final'},
        {title: 'В работе', value: 'work'},
        {title: 'Черновик', value: 'draft'},
      ],
      selectedStatus: {
        value: '',
        title: '',
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      timer: null,
      filter: {
        checkbox: [false, false, false, false],
      },
      date: '',
      selectedCreatedBy: {
        value: '',
        title: '',
      },
      filtering: {},
      shortcuts,
    }
  },

  async mounted() {
    await EstimatesService.getStatusesEstimates().then(r => {
      this.statuses = r.data.data
    })
    await UsersService.getAllUsers(true)
  },

  computed: {
    storeUsers() {
      return this.$store.state.users.users
    },
  },

  watch: {
    orderNumber() {
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        this.$store.commit(`estimates/getOrderNumber`, this.orderNumber)
        this.$emit('update-data')
      }, 500)
    },
    selectedStatus() {
      this.$store.commit(`estimates/getStatus`, this.selectedStatus.value)
      this.getFilter()
    },
  },

  methods: {
    selectDay,
    resetFilter() {
      this.$store.commit(`estimates/getSearch`, '')
      this.orderNumber = ''
      this.date = ''
      this.filtering = {}
      this.filter.checkbox = [false, false, false, false]
      this.selectedStatus = {
        value: '',
        title: '',
      }
      this.selectedCreatedBy = {
        value: '',
        title: '',
      }
      this.getFilter()
    },
    closeLeftSidebar() {
      this.$store.commit('sidebars/changeLeftSidebar', false)
    },
    changeCreatedBy(event) {
      if (this.selectedCreatedBy.value === null) {
        delete this.filtering['created_by']
      } else {
        this.filtering['created_by'] = event.value
      }

      this.getFilter()
    },
    inputCheckbox(num, event) {
      let a = this.filter.checkbox[num]

      this.filter.checkbox = [false, false, false, false]

      if (event) {
        this.filter.checkbox[num] = true
      }
      this.date = ''

      delete this.filtering['created_at']
      delete this.filtering['status_active_at']
      delete this.filtering['partner_status_finish_at']
      if (!a) {
        if (num === 0) this.filtering['created_at'] = 'today'
        if (num === 1) this.filtering['created_at'] = 'yesterday'
        if (num === 2) this.filtering['created_at'] = 'last_seven_days'
        if (num === 3) this.filtering['created_at'] = 'last_month'
      }

      this.getFilter()
    },
    changeRangeDate(date, event, name) {
      this.filter.checkbox = [false, false, false, false]
      if (event[0] === null && event[1] === null) {
        delete this.filtering[name]
      } else {
        this.filtering[name] =
          moment(this[date][0], 'DD.MM.YYYY').format('YYYY-MM-DD') +
          '|' +
          moment(this[date][1], 'DD.MM.YYYY').format('YYYY-MM-DD')
      }
      this.getFilter()
    },
    getFilter() {
      let data = ''
      let counter = 0
      let listCreatedName = ['today', 'yesterday', 'last_seven_days', 'last_month']

      delete this.filtering['title']
      delete this.filtering['status_id']

      if (this.selectedStatus.value) this.filtering['status_id'] = this.selectedStatus.value

      for (let key in this.filtering) {
        data += key + ':' + this.filtering[key]
        if (!listCreatedName.includes(this.filtering[key])) {
          counter++
        }
        if (Object.keys(this.filtering).length !== counter) data += ';'
      }

      this.$store.commit('estimates/filterMain', data)
      this.$store.commit('estimates/getSearchCount', counter)

      this.$emit('update-data')
    },
    onShortcutClick(type, shortcut, name) {
      this[type] = selectDay(shortcut)
      this.changeRangeDate(type, this[type], name)
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
}
</script>

<style lang="scss">
.filter-roles {
  .mx-datepicker {
    width: 100%;
  }

  .mx-input-wrapper {
    .mx-input {
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      padding: 6px 6px 6px 8px;
      height: unset;
      border: 1px solid #f0f0f0;
      -webkit-box-shadow: unset;
      box-shadow: unset;
    }

    .mx-input:hover,
    .mx-input:focus {
      border-color: $color-accent;
    }

    .mx-input::-webkit-input-placeholder {
      color: #9ba6b3;
    }

    .mx-input::-moz-placeholder {
      color: #9ba6b3;
    }

    .mx-icon-calendar {
      color: #9ba6b3;
      font-size: 13px;
    }
  }
}
</style>

<style lang="scss" scoped>
.filter-roles {
  display: block;
  padding: 32px 24px;
  min-width: 192px;
  overflow-y: auto;
  height: 100%;

  &__btn-block {
    display: flex;
    justify-content: space-between;
  }

  &__btn-left {
    display: flex;
    align-items: center;

    &__label {
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
      color: $color-mine-shaft;
    }

    &__reset-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: $color-accent;
      text-align: right;
      flex: 1;
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__label {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $color-gull-gray;
    text-align: left;
    margin-bottom: 3px;
  }

  &__checkbox-block {
    margin-top: 8px;

    label:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__period-block {
    display: flex;
    margin-top: 8px;
    flex-direction: column;
  }

  &__btn-period {
    width: 100%;
    padding: 5px 6px 5px 8px;
    border: 1px solid $color-catskill-grey;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    cursor: pointer;
    background-color: $color-white;
    color: $color-gull-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: $color-iron;
      border: 1px solid $color-iron;
      //color: $color-oslo-gray;
      opacity: 0.8;
    }
  }

  &__icon-period {
    width: 16px;
    height: 16px;
    background-image: url('../assets/icons/calendar.svg');
  }

  &__dropdown {
    //margin-top: 8px;
  }
}

@media screen and (max-width: 1440px) {
  .filter-roles {
    padding: 20px;
    min-width: 152px;
  }
}

@media screen and (max-width: 450px) {
  .filter-roles {
    &__dropdown,
    &__checkbox-block {
      width: 180px;
    }
  }
}
</style>